<template>
  <div>
    <div class="content">
      <div class="SearchBar">
        <el-button  @click="closeadd" size="mini" class="backbtn">返回</el-button>
          <el-row class="row">
            <el-col :span="8">
              采集名称: {{ form.itemName }}
            </el-col>
            <el-col :span="8">
              必填项:  {{ form.requiredFields == 1 ? '必填' : '非必填' }}
            </el-col>
            <el-col :span="8">
              启用状态: {{ form.generalStatus ==1 ? '启用' : '禁用' }}
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="8">
              录入类型: {{ starts(form.inputType) }}
            </el-col>
            <el-col :span="8">
              排序: {{ form.sortNum }}
            </el-col>
          </el-row>
          <el-row>
            <el-table
              ref="tableData"
              v-if="form.inputType != 4 && form.inputType != 1 && form.inputType != 5"
              :data="form.itemss"
              style="width: 90%;margin:0px 0px 20px 45px"
              border
              :stripe="true"
            >
              <el-table-column prop="content" label="选项" align="center" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="type" label="分类" align="center" show-overflow-tooltip>
                <template slot-scope="{row}">
                  {{row.type == 1 ? '正常项' : '异常项'}}
                </template>
              </el-table-column>
              <el-table-column prop="photo" label="是否拍照" align="center" show-overflow-tooltip>
                <template slot-scope="{row}">
                  {{row.takingPictures == 1 ? '是' : '否'}}
                </template>
              </el-table-column>
              <el-table-column prop="note" label="是否补充描述" align="center" show-overflow-tooltip>
                <template slot-scope="{row}">
                  {{row.describe == 1 ? '是' : '否'}}
                </template>
              </el-table-column>
            </el-table>
          </el-row>
      </div>
      <div class="SearchBar1" v-for="(item,index) in form.itemsJuniors" :key="index">
        <el-form label-position="right" label-width="110px">
          <div class="titleDiv">
            <span style="font-weight: bolder">{{ item.pcontent }}的关联项</span>
          </div>
          <el-row class="row2">
            <el-col :span="8">
              <el-form-item label="采集名称:" prop="result">
                {{ item.juniorName }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="必填项:" prop="result">
                {{ item.juniorRequiredFields == 1 ? '是' : '否' }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="录入类型:" prop="result">
                {{ starts(item.juniorInputType) }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-table
              ref="tableData"
              :data="item.itemsJuniorss"
              v-if="item.juniorInputType != 4 && item.juniorInputType != 1"
              style="width: 100%;margin:0px 0px 20px 45px"
              border
              :stripe="true"
            >
              <el-table-column prop="juniorsContent" label="选项" align="center" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="types" label="分类" align="center" show-overflow-tooltip>
                <template slot-scope="{row}">
                  {{row.juniorsType == 1 ? '正常项' : '异常项'}}
                </template>
              </el-table-column>
              <el-table-column prop="photo" label="是否拍照" align="center" show-overflow-tooltip>
                <template slot-scope="{row}">
                  {{row.juniorsTakingPictures == 1 ? '是' : '否'}}
                </template>
              </el-table-column>
              <el-table-column prop="note" label="是否补充描述" align="center" show-overflow-tooltip>
                <template slot-scope="{row}">
                  {{row.juniorsDescribe == 1 ? '是' : '否'}}
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { itemDetails2 } from "@/RequestPort/dict/cycle";
export default {
  data() {
    return {
      form:{},
      tableData: [
        {option:'大清洗',types:'必填',photo:'是',note:'是'},
        {option:'小清洁',types:'非必填',photo:'是',note:'否'},
      ], //  表格数据
    }
  },
  mounted() {
    console.log(this.$route.query.id)
    this.particulars(this.$route.query.id)
  },
  methods: {
    starts(val){
      if(val == 1) return '文本框'
      if(val == 2) return '单选'
      if(val == 3) return '多选'
      if(val == 4) return '上传照片'
      if(val == 5) return '数字框'
    },
    //返回
    closeadd() {
      // this.$router.go(-1)
      this.$router.push({ path: '/home/checkDict', query: { first: 1, screcd: 1 } })
    },
    particulars(id) {
      itemDetails2({id}).then(res =>{
        this.form = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  background: #f3f4f8;;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 150px);
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.SearchBar1 {
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  padding-top: 1px;
}
.backbtn{
  margin:30px 0px 40px 30px;
  width: 82px;
  height: 36px;
}
.row{
  margin-left: 45px;
  margin-bottom: 20px;
}
.row2{
  margin-left: 10px;
  margin-top: 10px;
  width: 100%;
}
.titleDiv{
  margin: 26px 0px 0px 30px;
}
</style>
